import Component from '../../util/Component';
import Page from '../Base/Page';
import Link from 'next/link';
import PropTypes from 'prop-types';
import OffCanvas from '../Base/OffCanvas';
import config from '../../config';
import { withRouter } from 'next/router';

@withRouter
export default class extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  toggleOffCanvas = () => {
    this.$.OffCanvas.toggle();
  };

  getActive(path) {
    return this.props.router.asPath === path;
  }

  render() {
    return (
      <Page>
        <div>
          <div className="navbar pt-4 pb-4 container grid-xl">
            <section className="navbar-section">
              <a
                className="navbar-logo"
                style={{ height: 'auto' }}
                href={config.landingHost}
              >
                <img
                  className="img-responsive hide-sm"
                  style={{ height: '2rem' }}
                  src={`${config.cdnHost}${config.cdnPath}/${config.logoDarkLong}`}
                />
                <img
                  className="img-responsive show-sm"
                  style={{ height: '2rem' }}
                  src={`${config.cdnHost}${config.cdnPath}/${config.logoDark}`}
                />
              </a>
            </section>
            <section className="navbar-section">
              <div className="input-group input-inline hide-md">
                <Link href="/auth/reset">
                  <a className="btn btn-link">Reset Password</a>
                </Link>
                <Link href="/auth/signup">
                  <a className="btn btn-link">Sign up</a>
                </Link>
                <Link href="/auth/login">
                  <a className="btn btn-link">Login</a>
                </Link>
              </div>
              <a
                className="off-canvas-toggle btn btn-action show-md"
                onClick={this.toggleOffCanvas}
              >
                <i className="icon icon-menu" />
              </a>
            </section>
          </div>
          <OffCanvas side="right">
            <div className="mb-8 p-4" key="sidebar">
              <ul className="nav col-12">
                <li
                  className={`nav-item ${
                    this.getActive('/auth/login') ? 'active' : ''
                  }`}
                >
                  <Link href="./login">
                    <a>Login</a>
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    this.getActive('/auth/signup') ? 'active' : ''
                  }`}
                >
                  <Link href="./signup">
                    <a>Sign up</a>
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    this.getActive('/auth/reset') ? 'active' : ''
                  }`}
                >
                  <Link href="./reset">
                    <a>Reset Password</a>
                  </Link>
                </li>
              </ul>
            </div>
          </OffCanvas>
          <div className="container grid-xl">
            <div className="py-16 px-1">
              <div
                style={{ maxWidth: '480px', width: '100%', margin: '0 auto' }}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

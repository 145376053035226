import Component from '../../util/Component';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

@observer
export default class extends Component {
  static propTypes = {
    side: PropTypes.string,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    visible: false,
  };

  dismissOffCanvas = () => {
    this.$.OffCanvas.dismiss();
  };

  render() {
    let styles = {
      sidebar: {
        maxWidth: '14rem',
      },
      content: {
        minHeight: '100vh',
        padding: '0',
        margin: '0',
        overflow: 'auto',
      },
    };

    return (
      <div
        className={`off-canvas ${this.props.side || 'left'} ${
          this.props.visible ? 'off-canvas-sidebar-show' : ''
        }`}
      >
        <div
          className={`off-canvas-sidebar ${
            this.$.OffCanvas.isOpen() ? 'active' : ''
          }`}
          style={styles.sidebar}
        >
          <div className="navbar">{this.getComponent('sidebar')}</div>
        </div>
        <a className="off-canvas-overlay" onClick={this.dismissOffCanvas} />
        {this.getComponent('content').length ? (
          <div className="off-canvas-content" style={styles.content}>
            {this.getComponent('content')}
          </div>
        ) : null}
      </div>
    );
  }
}
